.cidr-new-request-heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

.cidr-check-mark {
  color: #3ac4aa;
  font-size: 42px;
}

.cidr-error-mark {
  color: red;
  font-size: 42px;
}

.cidr-modal-success-msg {
  font-size: 16px;
  color: #28323c;
}

.cidr-modal-ref-msg {
  font-size: 14px;
  color: #28323c;
}

.cidr-ref-number {
  font-weight: bold;
  font-size: 14px;
  color: #28323c;
}

.cidr-inlined-btn {
  background-color: #3ac4aa;
  color: white;
  font-size: 16px;
  border-radius: 7px;
  border: none;
  padding: 8px;
}

.cidr-outlined-btn {
  color: #3ac4aa;
  font-size: 16px;
  border-radius: 7px;
  border: 1px solid #3ac4aa;
  padding: 8px;
  background-color: white;
}

.cidr-note {
  font-size: 14px;
  color: #585858;
  font-weight: bold;
}
