.react-chatbot-kit-chat-container {
  position: fixed !important;
  right: 3px;
  bottom: 0;
  width: 450px !important;
  z-index: 9998;
}

.react-chatbot-kit-chat-inner-container {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(58, 196, 170, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(58, 196, 170, 1);
  box-shadow: 0px 0px 5px 0px rgba(58, 196, 170, 1);
  width: 450px !important;
  height: 530px !important;
}
.react-chatbot-kit-chat-bot-message {
  width: auto !important;
  background-color: #ddf3ee !important;
  color: #000000 !important;
  word-break: break-word;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #ddf3ee !important;
}
.react-chatbot-kit-user-chat-message {
  color: black !important;
  word-break: break-word;
}
.bot-custom-header {
  // border-image: linear-gradient(
  //   90.3deg,
  //   #3ac4aa 0%,
  //   #6ddbc7 33.33%,
  //   #87e9d7 66.67%,
  //   #a9ffef 100%
  // )
  // 1 / 1 / 0 stretch;
  border-image: linear-gradient(
      90.3deg,
      #a9ffef 0%,
      #3ac4aa 33.33%,
      #3ac4aa 66.67%,
      #a9ffef 100%
    )
    1 / 1 / 0 stretch;
  border-bottom: 4px solid;
  background-color: #3ac4aa !important;
  padding: 0px 14px;
  display: flex;
  flex-direction: row;
  color: white;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(193, 193, 193);
  border-radius: 10px;
}

/* Handle */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
  background: rgb(193, 193, 193);
  border-radius: 10px;
}

/* Handle on hover */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb:hover {
  background: rgb(193, 193, 193);
}
.react-chatbot-kit-chat-btn-send {
  background-color: #3ac4aa !important;
}

.react-chatbot-kit-chat-input {
  border-top: 1px solid #ddf3ee !important;
}

/* // option prompt */
.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.option-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 6px 10px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #3ac4aa;
  text-align: left;
}

.option-button:hover {
  background-color: white;
  color: black;
  border: 1px solid #3ac4aa;
  padding: 6px 10px;
  margin: 5px 0;
}
.ask-me-anything {
  text-align: left;
  font-size: 13px;
  color: #3ac4aa;
  font-weight: 500;
}
.react-chatbot-kit-chat-bot-message:has(div.chatbot-loader-container) {
  background-color: white !important;
  width: auto !important;
  margin-left: 0;
  padding-left: 0 !important;
}
.react-chatbot-kit-chat-bot-message:has(div.chatbot-loader-container)
  .react-chatbot-kit-chat-bot-message-arrow {
  display: none;
}
input.react-chatbot-kit-chat-input:focus-visible
  + button.react-chatbot-kit-chat-btn-send {
  /* outline: 2px solid #3ac4aa; */
  box-shadow: 0px -2px 0px 0px #3ac4aa, 0px 2px 0px 0px #3ac4aa;
}
input.react-chatbot-kit-chat-input:focus-visible {
  outline: 2px solid #3ac4aa;
}
button.react-chatbot-kit-chat-btn-send:hover {
  background-color: #58d7c0 !important;
}
.app-chatbot-button {
  border-radius: 36px;
  background-color: #3ac4aa;
  border: 2px solid #3ac4aa;
  padding: 6px !important;
  bottom: 15px;
  position: fixed;
  z-index: 9999;
}
.right--30 {
  right: 31%;
}
.right--0 {
  right: 1%;
}
.react-chatbot-kit-chat-message-container {
  overflow: auto !important;
  height: 384px !important;
}
.bot-header-text {
  flex-grow: 4;
  justify-content: start;
  display: flex;
  margin: auto;
}
.bot-close-icon {
  display: flex;
  justify-content: center;
  margin: auto;
  color: #ffff;
  font-weight: bolder;
  // border: 1px solid #3ac4aa;
  border-radius: 35px;
  padding: 0px 7px;
}
.cursor-pointer {
  cursor: pointer;
}
.user-avatar {
  margin: 4px 0px 0px 10px;
  /* border: 2px solid #3ac4aa; */
  /* display: flex; */
  /* vertical-align: middle; */
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0px 5px;
  border-radius: 35px;
  background-color: #3ac4aa;
  color: white;
  font-size: 13px;
  height: 35px;
  align-items: center;
}
.chat-bot-icon {
  background-color: #ebab36;
  width: 35px !important;
  height: 35px !important;
  padding: 5px;
  border-radius: 35px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 4px 10px 0px 0px;
}
.bot-dp-remover + div,
.react-chatbot-kit-chat-bot-message-container
  .react-chatbot-kit-chat-bot-message {
  margin-left: unset;
}
.user-dp-remover {
  margin-right: unset;
}
.main-option-position {
  bottom: 8.8% !important;
  transition: bottom 1s, background 2s;
  background: #f1f5f7 !important;
}
.container-position {
  position: absolute;
  bottom: 47%;
  z-index: 9999;
  width: 100%;
  background: transparent;
  margin-left: -17.5px;
}
.options-container.intial-prompt-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 0;
}
button.option-button.intial-prompt {
  padding: 4px 12px;
  font-size: 12px;
  background-color: #ddf3ee;
}
button.option-button.intial-prompt:hover {
  padding: 4px 12px;
  background-color: white;
  color: black;
}
.initial-prompt-bg-remover {
  background-color: transparent !important;
}
.ask-nia {
  width: 60px;
  height: 60px;
  padding: 0 !important;
  color: white;
  line-height: 1.2;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  padding-top: 10px !important;
}
button.option-button.prompt-highlighter {
  background-color: #3ac4aa;
  color: white;
}
.header-bot-icon {
  padding: 5px;
  width: 50px;
  height: 50px;
  padding-top: 1px !important;
}

.app-chatbot-button.rotate {
  rotate: 360deg;
  transition: rotate 2s;
}
.chat--window #chat-Modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  right: 0;
  bottom: 0;
  width: 450px;
  height: 530px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.chat--window .chat-Modal-modal-content {
  padding: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin-top: 40%;
}

/* Add animation (slide in from top) */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.chat-modal-in {
  margin: auto 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 15px;
}

//bot tooltip
.chat-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text */
.chat-tooltip .chat-tooltiptext {
  visibility: hidden;
  padding: 4px 10px;
  text-align: center;
  border-radius: 6px;
  position: fixed;
  z-index: 9999;
  bottom: 15%;
  right: 0.5%;
  font-size: 13px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: #fff !important;
  color: #3ac4aa;
  font-weight: bold;
  border: 1px solid #3ac4aa;
}

/* Show the tooltip text when hovering */
.chat-tooltip:hover .chat-tooltiptext {
  visibility: visible;
  opacity: 1;
}
.chat-window-top {
  // background-color: #3ac4aa;
  background-color: #ebab36;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  padding: 12px;
  margin: 10px auto;
  display: block;
}
.react-chatbot-kit-chat-bot-message-container:has(h6) {
  display: none;
}
.chatbot-msg-table td,
.chatbot-msg-table th {
  border: 1px solid rgb(165 165 165) !important;
  text-align: center;
}

.react-chatbot-kit-chat-bot-message:has(table) {
  overflow: hidden;
}
.react-chatbot-kit-chat-bot-message span:has(table) {
  // white-space: pre;
  // white-space: pre-wrap;
  white-space: unset;
  word-break: break-word;
}
.react-chatbot-kit-chat-bot-message span {
  // white-space: pre;
  white-space: pre-wrap;
  word-break: break-word;
}
.disable-btn {
  pointer-events: none;
  opacity: 0.5;
}
.table-overflow-x-scroll {
  overflow-x: auto;
}
.chatbot-msg-table th {
  /* padding: 0 !important; */
  min-width: 73px;
}
.reaction-icons {
  width: 20px;
  height: auto;
}
.fs-9_4 {
  font-size: 9.4px;
}
.vertical-align-middle {
  vertical-align: middle;
}
.chat--window {
  height: 0;
}
.chatbot-apply-btn {
  width: max-content;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}

.chatbot-apply-btn-td {
  height: auto;
  vertical-align: middle;
}
.upload-checkbox input {
  margin-right: 0.5rem;
}
.upload-checkbox {
  font-size: 0.9rem;
}
.acceptable-format-text {
  font-size: 0.9rem;
}
.input-box-container-chatbot {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 8px;
  margin: 12px 43px;
}
.either-one-selection {
  font-weight: 600;
  font-size: 0.9rem;
}
.pointer-cursor-apply {
  cursor: pointer;
  color: #3ac4aa;
}
