.canvas-legend-list-wrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* width: 50%; */
  width: 100%;
  /* justify-content: center; */
  justify-content: end;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}

.canvas-legend-list {
  padding: 8px;
  display: flex;
  cursor: pointer;
}

.canvas-legend-list-active {
  display: flex;
  background-color: #ddf3ee;
  color: #1f9f87;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.canvas-legend-list-red {
  width: 16px;
  height: 16px;
  background-color: #ff3f3f;
  border-radius: 5px;
  margin-right: 10px;
}

.canvas-legend-list-yellow {
  width: 16px;
  height: 16px;
  background-color: #ffe181;
  border-radius: 5px;
  margin-right: 10px;
}

.canvas-legend-list-green {
  width: 16px;
  height: 16px;
  background-color: #69bc50;
  border-radius: 5px;
  margin-right: 10px;
}

.canvas-legend-list-orange {
  width: 16px;
  height: 16px;
  background-color: #fd7b43;
  border-radius: 5px;
  margin-right: 10px;
}
