.dashboard-title {
  font-size: 14px;
  font-weight: 600;
}

.dashboard-table-head {
  font-size: 12px;
  font-weight: 600;
}

.dashboard-table-body {
  font-size: 12px;
  font-weight: normal;
  color: #585858;
}

.custom-sticky-top-position {
  top: 65px !important;
}

.service-status-img {
  height: auto;
  width: auto;
  background-color: #f1f5f7;
  padding: 6px;
  border-radius: 10px;
}

.service-status-link {
  font-size: 12px;
  color: #3ac4aa;
  text-decoration: none;
  margin-left: 2px;
}

.service-status-description {
  font-size: 10px;
  margin-top: 8px;
}

/* Chart Logo Center */

.aws-chart-logo-center {
  background-image: url("../../images/aws-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}

.azure-chart-logo-center {
  background-image: url("../../images/azure-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}

/* DatePicker */

.react-datepicker__input-container input[type="text"] {
  padding: 4px !important;
}

.prisma-note {
  font-size: 10px;
  color: #585858;
  font-weight: bold;
}
.gcs-count-logo {
  width: 54.5px;
}
.dashboard-req-count {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 1px 0px;
  align-items: center;
  border: 1px solid #3ac4aa;
  border-radius: 8px;
  margin: 3px;
}
.dboard-title-center {
  text-align: center;
}
.new-workspc-req-title {
  color: #212529;
}
