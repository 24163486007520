.textfield_content{
    border: 1px #9CE1D4 solid;
    width: 100%;
    border-radius: 5px;
    resize: none;
}

.textfield_content::placeholder{
    padding-top: 20px;
    padding-left: 13px;
    color: #28323C;
    opacity: 60%;
}

.radio-btn{
    width: 20px;
    height: 20px;
    
    
}

.radio-btn:checked{
    color: #3AC4AA !important;
    background-color: #3AC4AA;
    border: 1px #3AC4AA solid;
}

.radiobutton{
    display: inline-block;
}

.inputElements{
    margin-bottom: 57px;
}

.uploadValueField{
    border: 1px #9CE1D4 solid;
    width: 50%;
    min-width: 50%;
    border-radius: 5px;
    height: 44px;
}

.uploadValueField::placeholder{
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 14px;
    color: #28323C;
    opacity: 60%;
}

.uploadSectionParent{
    width: 100%;
    position: relative;
}

.BrowseBtn{
    background-color: #3AC4AA;
    border: none;
    color: white;
    border-radius: 7px;
    width: 120px;
    height: 44px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.overHeadSection {
    position: relative;
    overflow: hidden;
    display: inline-block;
    opacity: 1;
    z-index: 99;
  }
  
  .overHeadBtn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    width: 120px;
    height: 44px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    
  }
  
  .overHeadSection input[type=file] {
    opacity: 0;
    position: absolute;
    left: 7px;
    top: 7px;
  }

  .arrowImg{
    width: 10px;
    height: 10px;
  }


  .arrowContainer{
    background-color: #d2d2d2;
    padding-left: 5px;
    border-radius: 5px;
    margin-top: 25px;
    width: 25px;
    height: 25px;
  }


  .SectionTitle{
    margin-bottom: 40px;
  }

  .DemandTitle{
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
  }

  .pageMargin{
    margin-left: 20px;
  }

  .file-input-container{
    position: relative;

    .file-icon{
      font-size: 24px;
      color: red;
      position: absolute;
      left: 0.5rem;
    }
  
    .file-remove-icon{
      font-size: 24px;
      position: absolute;
      right: 0.5rem;
      border:none;
    }
  }

  .scanned-results-container{
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
    border: 1px solid lightgray;
    border-style: solid !important;
    }

    tbody{
      tr{
        cursor: pointer;
      }
    }
  }
  
  .details-modal{
    .header{
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: capitalize !important;
      background-color: rgb(217, 227, 237);
    }

    .details-content{
      font-size: 0.75rem;
      font-weight: 600;
      color: grey;

      .row{
        margin-top: 8px;
      }
    }
  }

  
  
