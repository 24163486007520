@import '../variables.scss';

// View Alarms

.view-alarm-container {
  background-color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 8px;
  padding: 4px;
}

.view-alarm-key {
  color: #1d1d1db3;
  font-size: 12px;
  font-weight: $bold;
}

.view-alarm-data {
  color: #585858;
  font-size: 12px;
}

.view-alarm-sub-data{
  display: none;
}


.view-alarm-row:hover{

  .view-alarm-sub-data{
    display: inherit !important;
  }

  .fa.fa-angle-down{
    transition: all 0.6s ease;
    transform: rotate(180deg);
  }

}

// Snooze Alarm

.snooze-alarm-title {
  font-size: 12px;
  font-weight: $semiBold;
  margin-bottom: 6px;
}

.snooze-alarm-time-select {
  width: 110px;
}

.snooze-invalid-feedback {
  width: 110px;
  font-size: 11px;
}
