.report-widget-wrapper {
  display: flex;
  flex-direction: column;
}

.report-widget-logo-value-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.report-widget-img {
  height: 35px;

  // background-color: #f1f5f7;
  padding: 2px;
  border-radius: 10px;
}

.report-widget-value-font {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.gcs-count-logo {
  width: 35px;
}
