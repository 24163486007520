.cloud-preference-p {
  font-size: 16px !important;
  font-weight: bold !important;
  color: black !important;
}

.cloud-preference-img {
  height: 80px;
  width: 80px;
  background-color: white;
  padding: 4px;
  border-radius: 10px;
  
}

.logoContainer{
  width: 180px;
  height: 180px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer:hover{
  border: #3AC4AA 1px solid;
  box-shadow: 0px 4px 16px #ECEFF5;
}

.header-section-new{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

// disable trendmicro aws icon
.disable-div{
  pointer-events: none;
  opacity:0.7
}