@import '../variables.scss';

.passport-sidebar-container {
  position: relative;
  height: 100vh;
  background-color: white;
}

.passport-sidebar-logo-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 20px 0px 10px 0px;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0;
}

.passport-sidebar-container img {
  height: 25px;
  width: 25px;
}

.passport-sidebar-title {
  font-size: 13px;
  color: #262729;
  word-break: break-all;
  font-weight: $bold;
}

.passport-sidebar-link-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;

  li {
    padding: 8px;
  }
}

.passport-sidebar-link-active {
  background-color: #ddf3ee;
}

.passport-sidebar-link-active a {
  text-decoration: none !important;
  font-size: 12px;
  color: #337b6b !important;
  font-weight: 600;
  padding-left: 8px;
}

.passport-sidebar-link-inactive a {
  text-decoration: none !important;
  font-size: 12px;
  color: #585858 !important;
  padding-left: 8px;
}

@media screen and (max-width: 767px) {
  .passport-sidebar-container {
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
  }

  .passport-sidebar-link-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 8px 0px 0px 0px;
    margin-bottom: 0;

    li {
      text-align: center;
      padding: 8px;
      width: 50%;
    }
  }

  .passport-sidebar-link-active {
    background-color: unset;
    border-bottom: 1px solid $darkTeal;
  }

  .passport-sidebar-link-active a {
    text-decoration: none !important;
    font-size: 12px;
    color: #337b6b !important;
    font-weight: 600;
  }
}
