@import "variables.scss";

.page-content-sidebar-container {
  // position: fixed;
  // height: 100vh;
  // width: 140px;
  // top: 58px;
  // background-color: white;
  position: relative;
  height: 100vh;
  background-color: white;
}

.page-content-sidebar-logo-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 0px;
  justify-content: center;
  cursor: pointer;

  img {
    height: 25px;
    width: 25px;
  }

  a {
    .page-content-sidebar-logo-first-active {
      background-color: #ffffff;
      padding: 8px;
      border-radius: 7px 0px 0px 7px;
      box-shadow: 0px 1px 9px -3.5px #888888;
    }

    .page-content-sidebar-logo-first-inactive {
      background-color: #f0f0f0;
      padding: 8px;
      border-radius: 7px 0px 0px 7px;
    }

    .page-content-sidebar-logo-second-active {
      background-color: #ffffff;
      padding: 8px;
      border-radius: 0px 7px 7px 0px;
      box-shadow: 0px 1px 9px -3.5px #888888;
    }

    .page-content-sidebar-logo-second-inactive {
      background-color: #f0f0f0;
      padding: 8px;
      border-radius: 0px 7px 7px 0px;
    }
  }
}

.page-content-sidebar-link-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
}

li {
  padding: 8px;
}
.page-content-sidebar-link-active {
  background-color: #ddf3ee;
}

.page-content-sidebar-link-active a {
  text-decoration: none !important;
  font-size: 12px;
  color: $darkTeal !important;
  font-weight: 600;
}

.page-content-sidebar-link-inactive a {
  text-decoration: none !important;
  font-size: 12px;
  color: #585858 !important;
}

// After Sidebar Main Content Style

.page-content-main-container {
  margin-left: 140px;
}

@media screen and (max-width: 767px) {
  .page-content-sidebar-container {
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
    padding-bottom: 10px;
  }
}
