.alert-message {
  padding: 4px;
  border-radius: 7px;
  color: black;
  text-align: center;
}

.alert-message-danger {
  background-color: #f8d7da;
  border: 1px solid #f8d7da;
}

.info-green {
  color: rgb(113, 222, 113);
  margin-right: 8px;
}

.info-red {
  color: red;
  margin-right: 8px;
}