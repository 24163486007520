.splunk-img {
  width: 55px;
  height: 23px;
}

.splunk-link {
  color: #585858;
  font-weight: 500;
  font-size: 11px;
  text-decoration: none;
  margin-left: 6px;

  &:hover {
    text-decoration: underline;
    color: #585858;
  }
}

.aws-chart-logo-center-security {
  background-image: url('../../images/aws-logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px 35px;
}
