@import 'variables.scss';

.view-edit-box-conatiner {
  position: fixed;
  z-index: 1021;
  width: 550px;
  top: 58px;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  padding: 16px 16px 0px 16px;
}

.view-edit-box-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view-edit-box-back-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.view-edit-box-back-btn {
  margin-right: 20px;
  background-color: #edf0f6;
  border-radius: 7px;
  padding: 8px;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.view-edit-box-title {
  font-size: $subtitle;
  font-weight: $bold;
  margin-bottom: 0;
}

.view-edit-box-accountid {
  font-size: $subtitle;
  font-weight: $semiBold;
  margin-bottom: 0;
}

.view-edit-box-back-edit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 10%;
}

.view-edit-box-edit-btn {
  background-color: #edf0f6;
  border-radius: 7px;
  padding: 8px;
  cursor: pointer;
}

.view-edit-box-form-title-btn {
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 7px;
  color: #707070;
  padding: 4px;
  width: 100%;
  margin: 4px;
  font-size: $subtitle;
}

.view-edit-box-form-title-btn-active {
  border: 1px solid $darkTeal;
  background-color: $darkTeal;
  border-radius: 7px;
  color: #ffffff;
  padding: 4px;
  width: 100%;
  margin: 4px;
  font-size: $subtitle;
}

@media screen and (max-width: 767px) {
  .view-edit-box-conatiner {
    width: 100% !important;
    height: 100vh !important;
    overflow-y: scroll;
    z-index: 1024;
  }
}
