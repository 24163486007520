/* CAROUSEL */

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex !important;
}

.carousel-no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
