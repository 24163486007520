@import '../variables.scss';

.view-widget-icon {
  width: 55px;
  height: 55px;
  background-color: #f2f9f9;
  border-radius: 7px;
  padding: 1px;
}

.view-widget-count {
  font-size: 24px;
  color: #262729;
  font-weight: $bold;
}

.view-widget-title {
  font-size: 14px;
  color: #262729;
  font-weight: $semiBold;
}

.billing-title {
  font-size: 14px;
  color: #262729;
  font-weight: $semiBold;
}

.billing-total-value {
  color: #262729;
  font-size: 30px;
  font-weight: $bold;
}

.billing-total-text {
  color: #262729;
  font-weight: $regular;
  font-size: 14px;
}

.billing-view-account {
  color: #262729;
  font-weight: $regular;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.billing-more-details {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: $semiBold;
  color: $darkTeal;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $darkTeal !important;
  }
}
