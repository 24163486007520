.FormStepList {
  display: flex;
  overflow: hidden;
  margin: 1em auto 0.25em;
  padding-left: 0;
  width: 100%;
}

.FormStepList-item {
  flex: 1 1 50px;
  text-align: center;
  list-style: none;

  &:before {
    content: none !important;
    display: none !important;
  }
}

.FormStepIcon {
  display: inline-block;
  width: 100px;
  text-align: center;
  position: relative;

  &.is-active {
    .FormStepIcon-circle {
      background: #43b69a;
      border: 4px solid #8ed3c2;
      color: #fff;
    }
  }

  &.is-passed {
    &:after {
      background: #43b69a;
    }

    .FormStepIcon-circle {
      background: #43b69a;
      color: #fff;
    }
  }

  &:after {
    content: '';
    background: #d4d4d4;
    position: absolute;
    right: -1000%;
    top: 13px;
    left: 44px;
    height: 2px;
    z-index: 1;
  }

  &.FormStepIcon--last {
    &:after {
      background: #f1f5f7;
    }
  }
}

.FormStepIcon-circle {
  background: white;
  color: #a9a9a9;
  font-size: 16px;
  font-weight: bold;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  border: 1px solid #cdd2dd;
}

.FormStepIcon-title {
  display: block;
  font-size: 0.7em;
  margin-top: 0.5em;
}

.FormStepIcon-title-active {
  display: block;
  font-size: 0.7em;
  margin-top: 0.5em;
  color: #43b69a;
  font-weight: 600;
}
