$header-height: 58px;
$white-color: #ffffff;
$active-green-color: #1f9f87;
$top-bar-background-color: #f2f9f9;
$black-color: black;
$side-bar-width: 68px;

*,
::before,
::after {
  box-sizing: border-box;
}

/* Top Bar */

.top-bar {
  width: 100%;
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: $top-bar-background-color;
  z-index: 1021;
}

.top-bar-toggle {
  color: $active-green-color;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.5s;
}

.toggle-icon-padding {
  margin-left: calc(#{$side-bar-width} + 1rem);
}

/* Scrolling Text */
.scrolling-text-container {
  width: 767px;
  overflow: hidden;
}

.scrolling-text {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: auto !important;
  margin-left: 6px;
  margin-right: 6px;
  width: 767px;
}

.scrolling-text::-webkit-scrollbar {
  display: none;
}

.scrolling-text {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.scrolling-text li {
  background-color: #ddf3ee;
  padding: 2px 6px;
  display: inline;
  color: #1a9d84;
  margin: 0px 4px;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .top-bar {
    padding: 0 2rem 0 calc(#{$side-bar-width} + 2rem);
  }
  .toggle-icon-padding {
    padding-left: $side-bar-width;
  }
}

@media screen and (max-width: 767px) {
  .scrolling-text-container,
  .scrolling-text {
    display: none;
  }
}
