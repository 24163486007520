.approved-badge {
  background-color: #d9f4ee;
  border-radius: 7px;
  color: #2eb99f;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}

.pending-badge {
  background-color: #ffebde;
  border-radius: 7px;
  color: #fd7825;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}

.canceled-badge {
  background-color: #f8d7da;
  border-radius: 7px;
  color: #6a1a21;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}

.declined-badge {
  background-color: #f8d7da;
  border-radius: 7px;
  color: #6a1a21;
  padding: 7px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}
