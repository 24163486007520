.gitlab-container {
  display: grid;
  place-items: center;
  margin-top: 150px;
}

.gitlab-img {
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #dedede88;
  border-radius: 16px;
  padding: 30px;
}

.gitlab-text {
  margin-top: 2rem;
  font-weight: 600;
  color: #14121f;
  font-size: 18px;
}

.gitlab-btn {
  margin-top: 0.5rem;
  background-color: #3ac4aa;
  border: 1px solid #3ac4aa;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 12px;
}

// account-name-change-text

.account-name-change-text {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

//role-note-text
.role-note-text {
  font-size: 12px;
  font-weight: 500;
}
.font--bold {
  font-weight: bolder;
}
.note-text {
  font-size: 14px;
  margin-right: 10px;
}
.margin-left-8 {
  margin-left: 8px;
}
.note-text-border {
  border: 1px solid #c1c1c1;
  padding: 20px;
  background: #ddf3ee;
}

// bulk update
.account-list-checkbox-multiselect {
  text-align: center;
}
.account-list-checkbox-multiselect input.form-check-input {
  padding: 5.5px !important;
}
.checkbox-select-all {
  font-size: 11px;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 8px;
  vertical-align: middle;
}
.patch-actions {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 7px;
  vertical-align: middle;
}
.patch-btn {
  padding: 7px 19px !important;
}
.bulk-update-table-head > tr > th:first-child {
  vertical-align: middle;
  text-align: center;
}

.account-confirmation-table tbody,
.account-confirmation-table td,
.account-confirmation-table tfoot,
.account-confirmation-table th,
.account-confirmation-table thead,
.account-confirmation-table tr {
  border-color: #dddddd;
  border-style: solid !important;
}

.account-confirmation-table th,
.account-confirmation-table td {
  font-size: 14px;
  word-break: break-word;
}
