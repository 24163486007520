.custom-table {
  overflow-x: auto;
}

.custom-table-head {
  color: #1d1d1db3;
  font-size: 11px;
  letter-spacing: 0.28px;
}

.custom-table-body {
  color: #585858;
  font-size: 11px;
  vertical-align: middle !important;
}

.custom-table-option-conatiner {
  position: relative;
  padding: 6px;
}

.custom-table-option-icon {
  cursor: pointer;
}

.custom-table-option {
  position: absolute;
  width: 100px;
  top: 24px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 16px #dedede88;
  z-index: 1;
}

.custom-table-option ul {
  list-style: none;
  padding: 8px;
  margin-bottom: 0;
}

.custom-table-option ul li {
  padding: 8px;
  cursor: pointer;
}

/* Replace default style of table */
.table > :not(:first-child) {
  border-top: none !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none !important;
  border-width: 0;
}
