.optionListContainer .optionContainer {
  margin-bottom: 0 !important;
}

.month-datepicker {
  position: absolute;
  right: 1.5rem;
  width: auto;

  .calendar-img {
    position: absolute;
    z-index: 10;
    font-size: 1rem;
    left: 1rem;
  }

  .react-datepicker-wrapper {
    border: none;
    width: auto;

    input[type="text"] {
      border-radius: 8px;
      border: none;
      background-color: #e8eaeed8;
      padding-left: 3rem !important;
      width: auto;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important ;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.billing-custom-table {
  .custom-table-header {
    margin-right: 16rem;
  }
}

.billing-cost-legend {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #3ac4aa;
}
.spinner-billing {
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}
.billing-loader-bg {
  background: white;
  width: 40%;
  padding: 4px 30px 31px;
  border: 1px solid #d1d1d1;
  margin-top: 20px;
}

// billing-file-upload-csv

.billing-file-upload-csv label {
  display: block;
  text-align: center;
}
.billing-file-upload-csv input {
  bottom: 0;
  right: 0;
}
.billing-upload-dropdown .div:active {
  background-color: white !important;
}
// .billing-upload-container + div.billing-upload-dropdown .div {
//   background-color: #f1f5f7;
//   padding: 0.4rem 1.4rem;
// }
.file-upload-bg:hover {
  background-color: #ebebeb;
}
.file-upload-bg {
  background-color: #f1f5f7;
  width: 50%;
  margin: 0 auto;
  padding: 12px 24px 7px 24px;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.billing-upload-dropdown .dropdown-item > div {
  margin: 2px 5px;
}
.billing-action-type {
  font-size: 0.835rem;
  padding: 10px 0px;
  border: 2px solid #f1f1f1;
  width: 54%;
  text-align: left;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 500;
  // font-weight: bold;
}
.billing-action-type input#Append-File,
.billing-action-type input#Replace-File {
  margin-left: 10px;
}
.billing-action-type input#Append-File:focus,
.billing-action-type input#Replace-File:focus {
  box-shadow: unset;
}
.billing-action-type.a {
  margin-bottom: 5px;
  margin-top: 8px;
}
.billing-action-type.b {
  margin-bottom: 8px;
}
.billing-upload-container + div.billing-action-type-h {
  font-size: 0.9rem !important;
}
.teal-filled-btn.padding-8-20 {
  padding: 8px 17px;
}
.billing-upload-container + div.billing-upload-ul {
  margin: 0 1.4rem;
}

.billing-file-upload-csv button.custom-upload-button {
  height: 50px;
}
// button.billing-upload-container + div {
//   right: 15% !important;
//   top: 43px !important;
// }
#Append-File,
#Replace-File {
  float: unset;
  margin-right: 5px;
}
.file-name-label {
  word-break: break-all;
}
.margin-0-auto {
  margin: auto 0 !important;
}
.updated-by-div {
  height: 66px;
}
.chargeBack-desc.description {
  height: 36px;
  display: flex;
  align-items: center;
}
