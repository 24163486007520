.offcanvas-details-wrapper {
  position: fixed;
  z-index: 1023;
  width: 750px !important;
  top: 58px;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 8px 16px 0px 16px;
  height: 100%;
  overflow-y: scroll;
  // box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

.offcanvas-details-top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.offcanvas-details-back-heading {
  display: flex;
  width: 40%;
}

.offcanvas-legend-details {
  display: flex;
  width: 60%;
}

.offcanvas-details-icon-font-size {
  font-size: 18px;
  margin-right: 20px;
}

.offcanvas-details-table-head-font {
  font-size: 12px;
  font-weight: 700;
  color: #1d1d1db3;
}

.offcanvas-details-table-body-font {
  font-size: 12px;
  color: #585858;
}

@media screen and (max-width: 767px) {
  .offcanvas-details-wrapper {
    width: 100% !important;
    height: 100vh !important;
    overflow-y: scroll;
  }
}
