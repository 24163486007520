@import '.././variables.scss';

.full-layout-header {
  width: 100%;
  height: 58px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: #f2f9f9;
  z-index: 1021;
}

.full-layout-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 40%;
  padding-left: 10px;
}

.full-layout-logo {
  display: flex;
}

.full-layout-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  width: 60%;
}

.full-layout-right ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

// Icon Styles
.full-layout-icon {
  background-color: #edf0f6;
  border: 1px solid #edf0f6;
  border-radius: 7px;
  padding: 6px;
}

// Page Content

.full-layout-page-content {
  background-color: #f1f5f7;
  margin-top: 58px;
}
