.main {

    h6, .subtitle-text{
        font-weight: bold;
    }
    
    .img_div{
        flex: 0.25 1;
    }

    .desc_div{
        flex: 0.9;

        p{
            font-weight: 500;
        }
    }

    .view-edit-box-conatiner{
        width: 60%;
    }
    .img_div img{
        width: 12rem;
    }

}
