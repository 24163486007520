.border-1-radius-5 {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.public-DraftStyleDefault-block {
  margin: 0;
}

.notification-cloud-img {
  height: 80px;
  width: 80px;
  background-color: #89d7c8;
  /* padding: 24px; */
  border-radius: 43px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 800;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
