@import "variables.scss";

.teal-filled-btn {
  background-color: $darkTeal;
  color: #ffffff;
  border: 1px solid $darkTeal;
  border-radius: 7px;
  padding: 8px;
  font-weight: $semiBold;
  font-size: $subtitle;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  word-break: break-word;

  &:hover {
    text-decoration: none;
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: none;
    color: white;
  }

  i {
    font-size: $subtitle;
  }
}

.teal-bordered-btn {
  background-color: #ffffff;
  color: $darkTeal;
  border: 1px solid $darkTeal;
  border-radius: 7px;
  padding: 8px;
  font-weight: $semiBold;
  font-size: $subtitle;
  text-align: center;
  text-decoration: none;
  word-break: break-word;

  &:hover {
    text-decoration: none;
    color: $darkTeal;
  }

  a {
    text-decoration: none;
    color: $darkTeal;
  }

  a:hover {
    text-decoration: none;
    color: $darkTeal;
  }
}

.teal-bordered-download-btn-sm {
  background-color: #ffffff;
  color: $darkTeal;
  border: 1px solid $darkTeal;
  border-radius: 6px;
  padding: 4px;
  font-weight: $semiBold;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  word-break: break-word;
}

.teal-nobordered-btn {
  background-color: white;
  color: $darkTeal;
  border: none;
  padding: 8px;
  font-weight: $semiBold;
  font-size: $regularText;
  text-align: center;
  text-decoration: none;
}

// Button List

.btn-ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.btn-ul li {
  padding: 6px;
}
.color-teal {
  color: $darkTeal;
}
