.rqContainer {
    padding-left: 10px;
}


.resourceTitle{
    margin-top: 30px;
    font-size: 14px;
    font-weight: 700;
}
.radioContainer {
    padding-top:10px;
}

.rdSelContainer {
    padding-top: 10px;

}

.rqBtnStyle {
    margin-top:29px;
    width: 120px;
}
.rqResultContainer {
    background: rgba(255, 122, 89, 0);

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
    border: 1px solid lightgray;
    border-style: solid !important;
    }
}