.badge-wrapper {
  display: inline-block;
  padding: 0.4em 0.65em;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.red-badge {
  color: #ff3f3f;
  background-color: #ffddde;
}

.yellow-badge {
  color: #fd7825;
  background-color: #ffe181;
}

.green-badge {
  color: #69bc50;
  background-color: #d9f4ee;
}

.orange-badge {
  color: #fd7b43;
  background-color: #ffebde;
}
