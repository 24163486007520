// Colors

$darkTeal: #3ac4aa;
$lightTeal: #ddf3ee;

// Font Styles

$regular: 400;
$semiBold: 500;
$bold: 600;

$mainTitle: 18px;
$subtitle: 14px;
$regularText: 12px;
