$side-bar-width: 68px;
$side-bar-background-color: #f2f9f9;
$font-bold: bold;

/* SideBar Top Logo And Name */

.side-bar {
  position: fixed;
  top: 0;
  left: -30%;
  width: $side-bar-width;
  height: 100vh;
  background-color: $side-bar-background-color;
  padding: 0.5rem 0 0 0;
  transition: 0.5s;
  z-index: 1022;
}

.side-bar-nav {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.sidebar-logo-name-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 2rem;
  padding: 0 0 0.5rem 1.5rem;
  margin-bottom: 2rem;
  text-decoration: none;
  justify-content: center;
  margin-top: 8px;
}

.side-bar-logo {
  height: 30px;
  width: 30px;
  margin-top: -7px;
}

.side-short-logo-visibility {
  display: none;
}

.side-full-logo-visibility {
  display: none;
}

.side-bar-name {
  color: $black-color;
  font-weight: $font-bold;
}

/* SideBar Menu Links */

.side-bar-link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 2rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: #585858;
  cursor: pointer;
}

.side-bar-link:hover {
  text-decoration: none;
  color: #585858;
}

.side-bar-link-active {
  background-color: #ddf3ee;
  color: #5bb9a6;
  font-weight: bold;
}

.side-bar-menu-logo {
  height: 21px;
  width: 21px;
}

.side-bar-link-name {
  font-size: 12px;
}

/* SideBar with Submenu Links */

/* Common Styles */

.side-bar-submenu {
  position: absolute;
  left: 100%;
  width: 220px;
  background-color: white;
  box-shadow: 0px 4px 16px #dedede88;
}

.side-bar-submenu ul {
  list-style: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

.side-bar-submenu ul a {
  text-decoration: none;
  color: #14121f;
  font-weight: 500;
}

.side-bar-submenu ul li {
  font-size: 12px;
  color: #14121f;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 12px 8px 12px 18px;
}

.side-bar-submenu ul li:hover {
  background-color: #f2f9f9;
}

.side-bar-submenu-img {
  width: 25px;
  height: 18px;
}

.arrow-left:after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 33%;
  border-top: 6px solid transparent;
  border-right: 10px solid white;
  border-left: none;
  border-bottom: 6px solid transparent;
}

/* Request Menu */

#requests-menu:hover #requests-submenu {
  display: block;
}

#requests-submenu {
  display: none;
}

// Account Request

#accountrequest-requests #accountrequest-requests-img1 {
  display: block;
}

#accountrequest-requests #accountrequest-requests-img2 {
  display: none;
}

#accountrequest-requests:hover #accountrequest-requests-img1 {
  display: none;
}

#accountrequest-requests:hover #accountrequest-requests-img2 {
  display: block;
}

// Workspace request

#workspacerequest-requests #workspacerequest-requests-img1 {
  display: block;
}

#workspacerequest-requests #workspacerequest-requests-img2 {
  display: none;
}

#workspacerequest-requests:hover #workspacerequest-requests-img1 {
  display: none;
}

#workspacerequest-requests:hover #workspacerequest-requests-img2 {
  display: block;
}

// Manage AD Request

#managead-requests #managead-requests-img1 {
  display: block;
}

#managead-requests #managead-requests-img2 {
  display: none;
}

#managead-requests:hover #managead-requests-img1 {
  display: none;
}

#managead-requests:hover #managead-requests-img2 {
  display: block;
}

// aws-host-patch-management

#aws-host-patch-request #AWS-host-patch-management-img1 {
  display: block;
}

#aws-host-patch-request #AWS-host-patch-management-img2 {
  display: none;
}

#aws-host-patch-request:hover #AWS-host-patch-management-img1 {
  display: none;
}

#aws-host-patch-request:hover #AWS-host-patch-management-img2 {
  display: block;
}

// AWS Backup Request
#backup-requests #backup-requests-img1 {
  display: block;
}

#backup-requests #backup-requests-img2 {
  display: none;
}

#backup-requests:hover #backup-requests-img1 {
  display: none;
}

#backup-requests:hover #backup-requests-img2 {
  display: block;
}

// AWS Q request
#q-requests #aws-cidr-img1 {
  display: block;
}

#q-requests #aws-cidr-img2 {
  display: none;
}

#q-requests:hover #aws-cidr-img1 {
  display: none;
}

#q-requests:hover #aws-cidr-img2 {
  display: block;
}

// AWS AI request
#ai-request #aws-ai-img1 {
  display: block;
  width: 25px;
  height: 25px;
}

#ai-request #aws-ai-img2 {
  display: none;
}

#ai-request:hover #aws-ai-img1 {
  display: none;
}

#ai-request:hover #aws-ai-img2 {
  display: block;
  width: 25px;
  height: 25px;
}

/* Network Menu  */

#network-menu:hover #network-submenu {
  display: block;
}

#network-submenu {
  display: none;
}

#aws-cidr #aws-cidr-img1 {
  display: block;
}

#aws-cidr #aws-cidr-img2 {
  display: none;
}

#aws-cidr:hover #aws-cidr-img1 {
  display: none;
}

#aws-cidr:hover #aws-cidr-img2 {
  display: block;
}

#azure-cidr #azure-cidr-img1 {
  display: block;
}

#azure-cidr #azure-cidr-img2 {
  display: none;
}

#azure-cidr:hover #azure-cidr-img1 {
  display: none;
}

#azure-cidr:hover #azure-cidr-img2 {
  display: block;
}

#network-hub #network-hub-img1 {
  display: block;
}

#network-hub #network-hub-img2 {
  display: none;
}

#network-hub:hover #network-hub-img1 {
  display: none;
}

#network-hub:hover #network-hub-img2 {
  display: block;
}

//vpc-end-point-logo

#vpc-endpoint #vpc-endpoint-img2 {
  display: none;
}
#vpc-endpoint #vpc-endpoint-img1 {
  display: block;
}

#vpc-endpoint:hover #vpc-endpoint-img2 {
  display: block;
}
#vpc-endpoint:hover #vpc-endpoint-img1 {
  display: none;
}

/* Self Service Menu */

#self-service-menu:hover #self-service-submenu {
  display: block;
}

#self-service-submenu {
  display: none;
}

#ops-cost-menu:hover #ops-cost-submenu {
  display: block;
}

#ops-cost-submenu {
  display: none;
}

#gitlab-selfservice #gitlab-selfservice-img1 {
  display: block;
}

#gitlab-selfservice #gitlab-selfservice-img2 {
  display: none;
}

#gitlab-selfservice:hover #gitlab-selfservice-img1 {
  display: none;
}

#gitlab-selfservice:hover #gitlab-selfservice-img2 {
  display: block;
}

#okta-selfservice #okta-selfservice-img1 {
  display: block;
}

#okta-selfservice #okta-selfservice-img2 {
  display: none;
}

#okta-selfservice:hover #okta-selfservice-img1 {
  display: none;
}

#okta-selfservice:hover #okta-selfservice-img2 {
  display: block;
}

#request-training-selfservice #request-training-selfservice-img1 {
  display: block;
}

#request-training-selfservice #request-training-selfservice-img2 {
  display: none;
}

#request-training-selfservice:hover #request-training-selfservice-img1 {
  display: none;
}

#account-update-selfservice #account-update-selfservice-img2 {
  display: none;
}

#account-update-selfservice:hover #account-update-selfservice-img1 {
  display: none;
}

#account-update-selfservice:hover #account-update-selfservice-img2 {
  display: block;
}

// trend micro onboarding
#trend-micro-onboarding #trend-micro-onboarding-img2 {
  display: none;
}

#trend-micro-onboarding:hover #trend-micro-onboarding {
  display: none;
}

#trend-micro-onboarding:hover #trend-micro-onboarding-img2 {
  display: block;
}
//end

// Security dashboard
#tool-exception-Dashboard-img2,
#tool-exception-img2 {
  display: none;
}

#security-dashboard:hover #tool-exception-Dashboard-img1,
#tool-exception:hover #tool-exception-img1 {
  display: none;
}

#security-dashboard:hover #tool-exception-Dashboard-img2,
#tool-exception:hover #tool-exception-img2 {
  display: block;
}
//end

#request-training-selfservice:hover #request-training-selfservice-img2 {
  display: block;
}

// Templates

#templates-selfservice #templates-selfservice-img1 {
  display: block;
}

#templates-selfservice #templates-selfservice-img2 {
  display: none;
}

#templates-selfservice:hover #templates-selfservice-img1 {
  display: none;
}

#templates-selfservice:hover #templates-selfservice-img2 {
  display: block;
}

/* Utilities Menu */

#utilities-menu:hover #utilities-submenu {
  display: block;
}

#utilities-submenu {
  display: none;
}

/* Account Search */

#account-search-utilities #account-search-utilities-img1 {
  display: block;
}

#account-search-utilities #account-search-utilities-img2 {
  display: none;
}

#account-search-utilities:hover #account-search-utilities-img1 {
  display: none;
}

#account-search-utilities:hover #account-search-utilities-img2 {
  display: block;
}

/* Account Search */

// ops cost
#ops-cost #ops-cost-img1 {
  display: block;
}

#ops-cost #ops-cost-img2 {
  display: none;
}

#ops-cost:hover #ops-cost-img1 {
  display: none;
}

#ops-cost:hover #ops-cost-img2 {
  display: block;
}

// pam cost
#pam-cost #pam-cost-img1 {
  display: block;
}

#pam-cost #pam-cost-img2 {
  display: none;
}

#pam-cost:hover #pam-cost-img1 {
  display: none;
}

#pam-cost:hover #pam-cost-img2 {
  display: block;
}

// chargeback

#ChargeBack #ChargeBack-img1 {
  display: flex;
}

#ChargeBack #ChargeBack-img2 {
  display: none;
}

#ChargeBack:hover #ChargeBack-img1 {
  display: none;
}

#ChargeBack:hover #ChargeBack-img2 {
  display: flex;
}
#ChargeBack-img2 {
  color: #1a9d84;
}

/* Moriarty trigger */

#moriarty-utilities #moriarty-utilities-img1 {
  display: block;
}

#moriarty-utilities #moriarty-utilities-img2 {
  display: none;
}

#moriarty-utilities:hover #moriarty-utilities-img1 {
  display: none;
}

#moriarty-utilities:hover #moriarty-utilities-img2 {
  display: block;
}

// Passport

/* Notification */

#notification-utilities #notification-utilities-img1 {
  display: block;
}

#notification-utilities #notification-utilities-img2 {
  display: none;
}

#notification-utilities:hover #notification-utilities-img1 {
  display: none;
}

#notification-utilities:hover #notification-utilities-img2 {
  display: block;
}

/* To Show Side Bar */
.side-bar-show {
  left: 0;
}

/* Bigger Screen  */
@media screen and (min-width: 768px) {
  .side-bar {
    left: 0;
    padding: 1rem 0 0 0;
  }

  .side-bar-show {
    width: calc(#{$side-bar-width} + 150px);
    padding-right: 0 !important;
  }
}

/* Smaller Screen */
@media screen and (max-width: 767px) {
  .side-bar-full-logo {
    display: none;
  }

  .side-bar-logo {
    display: block;
  }
}
// tm note text
.note-font {
  font-size: 1rem;
}
.note-inner-font {
  font-size: 0.76rem;
}
