.date-range-select {
  position: relative;
  font-size: 12px;
  font-weight: normal;
  padding: 4px 8px;
  background-color: #edf0f6;
  border-radius: 5px;
}

/* Remedy Ticket Legend */

.remedy-legend {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-top: 20px;
}

.remedy-legend li {
  margin-right: 14px;

  div{
    display: flex;
  }
}

.remedy-incident {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 10px;

  &.closed{
    background-color: rgba(0,0,255,0.4);
  }
  &.open{
    background-color: rgba(255,0,0,0.4);
  }
}

.remedy-workorder {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 10px;

  &.closed{
    background-color: blue;
  }
  &.open{
    background-color: red;
  }
}
