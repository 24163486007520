.security-dashboard-count {
  width: calc(100% / 4 - 25px);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  border-radius: 5px;
  color: white;
}
.security-dashboard-count h6,
.second-graph h6 {
  font-size: 13.5px;
  font-weight: 500;
}
.bg-white-security {
  background-color: white;
}
.security-dashboard-count h6.fw-500 {
  font-weight: 400;
}
.mx-015 {
  margin-right: 0.15rem !important;
  margin-left: 0.15rem !important;
}
.doughnut-g {
  width: calc(100% / 4 - 18px);
  display: inline-block;
}
.doughnut-g-1 {
  margin: 0 15px;
}
.doughnut-g-2 {
  margin-left: 10px;
}
.doughnut-g-line {
  margin-right: 10px;
  width: calc(100% / 2 - 15px);
}
.fs-11 {
  font-size: 11px;
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}
.bg-blue {
  background-color: #68b1fa;
  color: white;
}
.memory-report {
  width: calc(100% / 3 - 17px);
  background-color: white;
}
.memory-icon {
  width: 15px;
  height: auto;
}
.tmConfirmity img,
.tmSecurity img {
  width: 75px;
  height: auto;
}
.check-them-out {
  background-color: #1ca3dc !important;
  border-color: #1ca3dc !important;
  padding: 0.5rem 1rem;
  font-size: 14px !important;
}
.manage-list {
  padding: 0.5rem 1rem;
  border-color: #4cbbd4;
  color: #4cbbd4;
}
