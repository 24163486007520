.cidr-view-title-container {
  padding: 6px;
}

.cidr-view-request-message {
  font-size: 12px;
}

.cidr-view-table-container {
  padding: 6px;
}

.cidr-view-table-container td {
  padding-left: 6px;
  font-size: 12px;
  padding-bottom: 10px;
}

.cidr-view-table-td-key {
  color: #585858;
}

.cidr-view-table-td-value {
  color: #28323c;
}
