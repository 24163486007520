@import '../variables.scss';

.filter-dropdown {
  padding: 10px;
  background-color: $darkTeal !important;
  border: 1px solid $darkTeal !important;
  border-radius: 7px;
}

.filter-dropdown::after {
  content: none;
}

.dropdown-item-text {
  font-size: 11px !important;
  font-weight: $semiBold !important;
}

.action-ul {
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.action-ul li {
  padding: 2px 12px;
}
