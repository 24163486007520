.legend-list-wrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}

.legend-list {
  padding: 8px;
  display: flex;
}

.legend-list-red {
  width: 16px;
  height: 16px;
  background-color: #ff3f3f;
  border-radius: 5px;
  margin-right: 10px;
}

.legend-list-yellow {
  width: 16px;
  height: 16px;
  background-color: #ffe181;
  border-radius: 5px;
  margin-right: 10px;
}

.legend-list-green {
  width: 16px;
  height: 16px;
  background-color: #69bc50;
  border-radius: 5px;
  margin-right: 10px;
}

.legend-list-orange {
  width: 16px;
  height: 16px;
  background-color: #fd7b43;
  border-radius: 5px;
  margin-right: 10px;
}

.legend-list-text {
  text-decoration: none !important;
}

/* Trend Micro */

.trend-micro-legend-list-wrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}

.trend-micro-legend-list {
  padding: 8px;
  display: flex;
}

.trend-micro-legend-list-red {
  width: 16px;
  height: 16px;
  background-color: #ff3f3f;
  border-radius: 5px;
  margin-right: 10px;
}

.trend-micro-legend-list-yellow {
  width: 16px;
  height: 16px;
  background-color: #ffe181;
  border-radius: 5px;
  margin-right: 10px;
}

.trend-micro-legend-list-green {
  width: 16px;
  height: 16px;
  background-color: #69bc50;
  border-radius: 5px;
  margin-right: 10px;
}

.trend-micro-legend-list-orange {
  width: 16px;
  height: 16px;
  background-color: #fd7b43;
  border-radius: 5px;
  margin-right: 10px;
}

.trend-micro-legend-list-text {
  text-decoration: none !important;
}
