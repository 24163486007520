@import 'variables.scss';

.custom-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.custom-loader-message {
  font-size: 10px;
  word-break: break-all;
  color: #585858;
}

.custom-danger-alert {
  background-color: #f8d7da;
  color: #842029;
  border: #f5c2c7;
  font-weight: $semiBold;
  font-size: $regularText;
  padding: 8px;
}

.form-error-message {
  font-size: 11px;
  color: #dc3545;
}
