@import '../variables.scss';

.documents-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.3rem;
}

.document-logo {
  font-size: 10px;
}

.document-title {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.documents-a-link {
  text-decoration: none;
  color: $darkTeal;
  font-size: 11px;
}

.documents-a-link:hover {
  text-decoration: none;
  color: $darkTeal;
}

.documents-description {
  color: black;
  font-size: $regularText;
  font-weight: $semiBold;
  text-decoration: none;
}

.documents-description:hover {
  text-decoration: none;
  color: black;
}
