.custom-error-message-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.custom-no-data-message-text {
  font-size: 12px;
}

.edit-module-validation-error {
  background-color: #fffafa !important;
  border: 1px solid #ff0017 !important;
  color: black !important;
}
.class-new-font {
  color: #606060;
  font-size: 14px;
}
.delete-btn-billing {
  border: 0 !important;
  opacity: 0.9;
  background-color: unset;
}
table tr td.border-right-0-billing {
  border-right: 0 !important;
}
.width-40 {
  width: 40%;
}
.class-new-font span:hover i,
.class-new-font span:hover {
  color: #3ac4aa;
  text-decoration: underline;
  cursor: pointer;
}
.margin-10-2 {
  margin-left: 2px;
  // padding-bottom: 3.7px;
}
.zero-width-height {
  width: 0;
  height: 0;
}
.month-selection-radio {
  font-size: 13px;
  // margin-left: 0.75rem;
  font-weight: 500;
}
.caret-icon-sorting {
  padding-bottom: 6px;
  vertical-align: middle;
}
.border-danger {
  // border: 1px solid #ffa6ae !important;
  border-radius: 2px;
}
.go-to-page {
  padding: 0 !important;
}
.z-index-99 {
  z-index: 99;
  position: relative;
}
.cursor-pointer-selection input,
.cursor-pointer-selection label,
.action-btns i {
  cursor: pointer;
}
.search-icon-billing {
  position: absolute;
  margin: 11px;
}
.action-btns {
  border: 1px solid #9ce1d4;
  padding: 0.4rem;
  background-color: white;
  border-radius: 4px;
}

//tootip css

.billing.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 0;
}

.billing.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #f2f9f9;
  color: #6a6a6a;
  border: 1px solid #bbbbbb;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  min-width: 100px;
  padding: 5px;
}

.billing.tooltip:hover .tooltiptext {
  visibility: visible;
}
button.generate-invoice {
  background-color: $darkTeal !important;
  color: #ffffff;
  border: 1px solid $darkTeal;
  border-radius: 7px;
  padding: 8px;
  font-weight: $semiBold;
  font-size: $subtitle !important;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  word-break: break-word;

  &:hover {
    text-decoration: none;
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: none;
    color: white;
  }

  i {
    font-size: $subtitle;
  }
}

.generate-invoice.dropdown-toggle::after {
  display: none;
}

.generate-invoice + div .dropdown-item {
  font-size: 12px !important;
  padding: 0.4rem 1rem;
}

.generate-invoice + div .dropdown-item-text {
  font-size: 13px !important;
  font-weight: 600 !important;
}
.generate-invoice + div .dropdown-menu {
  min-width: 15rem;
}
.generate-invoice + div ul {
  margin: 0 0.7rem;
}
.generate-invoice + div .form-check .form-check-input {
  float: unset;
  margin-left: unset;
}
.generate-invoice + div .form-check .form-check-input + label {
  margin-left: 0.5rem;
}
button.generate-invoice.fs-size-12,
button.teal-filled-btn.fs-size-12,
.fs-size-12 {
  font-size: 12px !important;
}
.padding-vertical {
  padding: 5.9px 8px !important;
  vertical-align: middle;
}
.service-status-description-p {
  font-size: 11px;
  font-weight: bolder;
}
.billing.tooltip .tooltiptext-month {
  visibility: hidden;
  width: auto;
  background-color: #f2f9f9;
  color: #6a6a6a;
  border: 1px solid #bbbbbb;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
  margin-left: -60px;
  min-width: max-content;
  padding: 5px;
  font-weight: 100;
}
.month-in:hover {
  border: 1px solid #3ac4aa;
}
.month-in {
  border: 1px solid white;
}
.selectedMonth {
  border: 1px solid #3ac4aa;
  background-color: #f2f9f9;
}
.bg-grey {
  background-color: #efefef;
}
.disabled-div {
  pointer-events: none;
  color: #c1c1c1;
}
#ChargeBack .side-bar-submenu-img::before {
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}
.disabled-bg {
  opacity: 0.5;
}
