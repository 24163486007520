@import '../variables.scss';

.metrics-label-title {
  font-size: 14px;
  font-weight: $semiBold;
  color: black;
}

.metrics-value-title {
  font-size: 14px;
  font-weight: $semiBold;
  color: $darkTeal;
}

.metrics-legend-wrapper {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 20px;

  li {
    display: flex;
    flex-direction: row;
  }

  .metrics-high {
    width: 16px;
    height: 16px;
    background-color: #3ac4aa;
    border-radius: 5px;
    margin-right: 10px;
  }

  .metrics-low {
    width: 16px;
    height: 16px;
    background-color: #b1efe4;
    border-radius: 5px;
    margin-right: 10px;
  }
}

.metrics-chart-title {
  color: #262729;
  font-size: 14px;
  font-weight: $bold;
}

.card-detail-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #eceff5;
  border-radius: 16px;
  padding: 8px;
}

.card-title {
  font-size: 14px;
  color: #262729;
  font-weight: $semiBold;
  margin-bottom: 12px;
}

.card-values {
  font-size: 12px;
  color: #585858;
}

.card-detail-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card-center-border {
  border-right: 1px solid $darkTeal;
  border-bottom: none;
  // margin-right: 2px;
}

.utc-note {
  color: $darkTeal;
}

.cpu-view-more {
  color: $darkTeal;
  font-size: 12px;
  text-decoration: underline;
  font-weight: $semiBold;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: $darkTeal;
  }
}

@media screen and (max-width: 767px) {
  .card-detail-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 4px;
  }

  .card-center-border {
    border-right: none;
    border-bottom: 2px solid #eceff5;
    // padding: 2px 12px;
    margin-bottom: 8px;
  }
}
