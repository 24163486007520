@import '../variables.scss';

.getstarted-title {
  color: #000000;
  font-size: 11px;
  text-decoration: none;
  font-weight: $semiBold;
}

.getstarted-subtitle {
  color: $darkTeal;
  font-size: 10px;
}

.accordion-header button {
  font-size: $regularText !important;
  font-weight: $semiBold;
}

.accordion-button {
  padding: 8px !important;
}

.accordion-button:not(.collapsed) {
  color: inherit !important;
  background-color: inherit !important;
}

.accordion-body {
  font-size: $regularText !important;
}

.accordion-collapse {
  background-color: $lightTeal !important;
}

.accordion-header [aria-expanded='true'] {
  border: 2px solid $darkTeal;
}

.accordion {
  :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
