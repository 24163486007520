@import 'variables.scss';

.maintitle-text {
  font-size: $mainTitle;
  font-weight: $bold;
  color: black;
}

.subtitle-text {
  font-size: $subtitle;
  font-weight: $semiBold;
  color: black;
}

.regular-text {
  font-size: $regularText;
  font-weight: $semiBold;
  color: black;
}

.regular-text-bold {
  font-size: $regularText;
  font-weight: $bold;
  color: black;
}

// User Dashboard

.user-dashboard-title {
  background-color: $lightTeal;
  padding: 4px;
  border-radius: 7px;
  font-size: 24px;
  font-weight: $bold;
  color: black;
}

.user-dashboard-quotes-text {
  font-size: $regularText;
  font-weight: $semiBold;
  color: black;
  text-align: center;
}

.user-dashboard-ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 1rem;
  height: 100%;
}

.custom-word-break {
  word-break: break-all;
}

.accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $lightTeal !important;
}

.accordion-question-icon {
  color: $darkTeal;
  margin-right: 8px;
  font-size: 18px;
}
